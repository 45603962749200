import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '~/hooks/use-auth';
import { useRouter } from '~/hooks/use-router';
import { paths } from '~/paths';
import type { FC, ReactNode } from 'react';
interface AuthGuardProps {
  children: ReactNode;
}
export const AuthGuard: FC<AuthGuardProps> = props => {
  const {
    children
  } = props;
  const router = useRouter();
  const {
    isAuthenticated
  } = useAuth();
  const [checked, setChecked] = useState<boolean>(false);
  const check = useCallback(() => {
    if (!isAuthenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname
      }).toString();
      const href = paths.auth.login + `?${searchParams}`;
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, router]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(() => {
    check();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};