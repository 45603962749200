import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from '~/hooks/use-auth';
import { useRouter } from '~/hooks/use-router';
import { useMeCache } from '~/queries/useMe';
import type { FC } from 'react';
interface UserPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}
export const UserPopover: FC<UserPopoverProps> = props => {
  const {
    anchorEl,
    onClose,
    open,
    ...other
  } = props;
  const router = useRouter();
  const {
    signOut
  } = useAuth();
  const {
    data: user
  } = useMeCache();
  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.();
      signOut();
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  }, [router, onClose]);
  return <Popover anchorEl={anchorEl} anchorOrigin={{
    horizontal: 'center',
    vertical: 'bottom'
  }} disableScrollLock onClose={onClose} open={!!open} PaperProps={{
    sx: {
      width: 200
    }
  }} {...other} data-sentry-element="Popover" data-sentry-component="UserPopover" data-sentry-source-file="user-popover.tsx">
			<Box sx={{
      p: 2
    }} data-sentry-element="Box" data-sentry-source-file="user-popover.tsx">
				<Typography variant="body1" data-sentry-element="Typography" data-sentry-source-file="user-popover.tsx">
					{user?.firstName} {user?.lastName}
				</Typography>
				<Typography color="text.secondary" variant="body2" data-sentry-element="Typography" data-sentry-source-file="user-popover.tsx">
					{user?.email}
				</Typography>
			</Box>
			{/* <Divider />
    <Box sx={{ p: 1 }}>
    <ListItemButton
    	component={RouterLink}
    	href="#"
    	onClick={onClose}
    	sx={{
    		borderRadius: 1,
    		px: 1,
    		py: 0.5,
    	}}
    >
    	<ListItemIcon>
    		<SvgIcon fontSize="small">
    			<User03Icon />
    		</SvgIcon>
    	</ListItemIcon>
    	<ListItemText primary={<Typography variant="body1">Profile</Typography>} />
    </ListItemButton>
    <ListItemButton
    	component={RouterLink}
    	href="#"
    	onClick={onClose}
    	sx={{
    		borderRadius: 1,
    		px: 1,
    		py: 0.5,
    	}}
    >
    	<ListItemIcon>
    		<SvgIcon fontSize="small">
    			<Settings04Icon />
    		</SvgIcon>
    	</ListItemIcon>
    	<ListItemText primary={<Typography variant="body1">Settings</Typography>} />
    </ListItemButton>
    <ListItemButton
    	component={RouterLink}
    	href={paths.index}
    	onClick={onClose}
    	sx={{
    		borderRadius: 1,
    		px: 1,
    		py: 0.5,
    	}}
    >
    	<ListItemIcon>
    		<SvgIcon fontSize="small">
    			<CreditCard01Icon />
    		</SvgIcon>
    	</ListItemIcon>
    	<ListItemText primary={<Typography variant="body1">Billing</Typography>} />
    </ListItemButton>
    </Box> */}
			<Divider sx={{
      my: '0 !important'
    }} data-sentry-element="Divider" data-sentry-source-file="user-popover.tsx" />
			<Box sx={{
      display: 'flex',
      p: 1,
      justifyContent: 'center'
    }} data-sentry-element="Box" data-sentry-source-file="user-popover.tsx">
				<Button color="inherit" onClick={handleLogout} size="small" data-sentry-element="Button" data-sentry-source-file="user-popover.tsx">
					Logout
				</Button>
			</Box>
		</Popover>;
};