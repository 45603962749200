import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { usePopover } from '~/hooks/use-popover';
import { LanguagePopover } from './language-popover';
import type { FC } from 'react';
type Language = 'en' | 'de' | 'es' | 'cn';
const languages: Record<Language, string> = {
  en: '/assets/flags/flag-uk.svg',
  de: '/assets/flags/flag-de.svg',
  es: '/assets/flags/flag-es.svg',
  cn: '/assets/flags/flag-cn.svg'
};
export const LanguageSwitch: FC = () => {
  const {
    i18n
  } = useTranslation();
  const popover = usePopover<HTMLButtonElement>();
  const flag = languages[i18n.language as Language];
  return <>
			<Tooltip title="Language" data-sentry-element="Tooltip" data-sentry-source-file="language-switch.tsx">
				<IconButton onClick={popover.handleOpen} ref={popover.anchorRef} data-sentry-element="IconButton" data-sentry-source-file="language-switch.tsx">
					<Box sx={{
          width: 28,
          '& img': {
            width: '100%'
          }
        }} data-sentry-element="Box" data-sentry-source-file="language-switch.tsx">
						<img src={flag} />
					</Box>
				</IconButton>
			</Tooltip>
			<LanguagePopover anchorEl={popover.anchorRef.current} onClose={popover.handleClose} open={popover.open} data-sentry-element="LanguagePopover" data-sentry-source-file="language-switch.tsx" />
		</>;
};