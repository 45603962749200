import { SvgIcon } from '@mui/material';
import { Bank, Settings01 } from '@untitled-ui/icons-react';
import { ClipboardCheck, CoinsHand, Cube01 } from '@untitled-ui/icons-react/build/esm';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from '~/paths';
import { useGetCurrentCompanyCache } from '~/queries/useGetCurrentCompany';
import type { ReactNode } from 'react';
export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}
export interface Section {
  items: Item[];
  subheader?: string;
}
export const useSections = () => {
  const {
    data
  } = useGetCurrentCompanyCache();
  const {
    t
  } = useTranslation();
  return useMemo<Section[]>(() => {
    return [{
      items: [{
        title: data?.fileAlias?.plural || '',
        path: paths.index,
        icon: <SvgIcon fontSize="small">
								<ClipboardCheck />
							</SvgIcon>
      }]
    }, {
      items: [{
        title: 'Agents',
        path: paths.agents.list,
        icon: <SvgIcon fontSize="small">
								<CoinsHand />
							</SvgIcon>
      }]
    }, {
      items: [{
        title: 'Clients',
        path: paths.clients.list,
        icon: <SvgIcon fontSize="small">
								<Bank />
							</SvgIcon>
      }]
    }, {
      items: [{
        title: 'Products',
        path: paths.products.list,
        icon: <SvgIcon fontSize="small">
								<Cube01 />
							</SvgIcon>
      }]
    }, {
      items: [{
        title: 'Settings',
        path: paths.settings,
        icon: <SvgIcon fontSize="small">
								<Settings01 />
							</SvgIcon>
      }]
    }];
  }, [t, data?.fileAlias]);
};