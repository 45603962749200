import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { useCallback, useState } from 'react';
import { Tip } from '~/components/tip';
import { wait } from '~/utils/wait';
import type { FC, SyntheticEvent } from 'react';
type Article = {
  description: string;
  title: string;
  path: string;
};
const articles: Record<string, Article[]> = {
  Platform: [{
    description: 'Provide your users with the content they need, exactly when they need it, by building a next-level site search experience using our AI-powered search API.',
    title: 'Level up your site search experience with our hosted API',
    path: 'Users / Api-usage'
  }, {
    description: 'Algolia is a search-as-a-service API that helps marketplaces build performant search experiences at scale while reducing engineering time.',
    title: 'Build performant marketplace search at scale',
    path: 'Users / Api-usage'
  }],
  Resources: [{
    description: 'Algolia’s architecture is heavily redundant, hosting every application on …',
    title: 'Using NetInfo API to Improve Algolia’s JavaScript Client',
    path: 'Resources / Blog posts'
  }, {
    description: 'Algolia is a search-as-a-service API that helps marketplaces build performant search experiences at scale while reducing engineering time.',
    title: 'Build performance',
    path: 'Resources / UI libraries'
  }]
};
interface SearchDialogProps {
  onClose?: () => void;
  open?: boolean;
}
export const SearchDialog: FC<SearchDialogProps> = props => {
  const {
    onClose,
    open = false,
    ...other
  } = props;
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayArticles, setDisplayArticles] = useState<boolean>(false);
  const handleSubmit = useCallback(async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();
    setDisplayArticles(false);
    setIsLoading(true);
    // Do search here
    await wait(1500);
    setIsLoading(false);
    setDisplayArticles(true);
  }, []);
  return <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open} {...other} data-sentry-element="Dialog" data-sentry-component="SearchDialog" data-sentry-source-file="search-dialog.tsx">
			<Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3} sx={{
      px: 3,
      py: 2
    }} data-sentry-element="Stack" data-sentry-source-file="search-dialog.tsx">
				<Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="search-dialog.tsx">Search</Typography>
				<IconButton color="inherit" onClick={onClose} data-sentry-element="IconButton" data-sentry-source-file="search-dialog.tsx">
					<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="search-dialog.tsx">
						<XIcon data-sentry-element="XIcon" data-sentry-source-file="search-dialog.tsx" />
					</SvgIcon>
				</IconButton>
			</Stack>
			<DialogContent data-sentry-element="DialogContent" data-sentry-source-file="search-dialog.tsx">
				<Tip message="Search by entering a keyword and pressing Enter" data-sentry-element="Tip" data-sentry-source-file="search-dialog.tsx" />
				<Box component="form" onSubmit={handleSubmit} sx={{
        mt: 3
      }} data-sentry-element="Box" data-sentry-source-file="search-dialog.tsx">
					<TextField fullWidth InputProps={{
          startAdornment: <InputAdornment position="start">
									<SvgIcon>
										<SearchMdIcon />
									</SvgIcon>
								</InputAdornment>
        }} label="Search" onChange={(event): void => setValue(event.target.value)} placeholder="Search..." value={value} data-sentry-element="TextField" data-sentry-source-file="search-dialog.tsx" />
				</Box>
				{isLoading && <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 3
      }}>
						<CircularProgress />
					</Box>}
				{displayArticles && <Stack spacing={2} sx={{
        mt: 3
      }}>
						{Object.keys(articles).map((type, index) => <Stack key={index} spacing={2}>
								<Typography variant="h6">{type}</Typography>
								<Stack divider={<Divider />} sx={{
            borderColor: 'divider',
            borderRadius: 1,
            borderStyle: 'solid',
            borderWidth: 1
          }}>
									{articles[type].map(article => <Box key={article.title} sx={{
              p: 2
            }}>
											<Stack alignItems="center" direction="row" spacing={2} sx={{
                pl: 1
              }}>
												<Badge color="primary" variant="dot" />
												<Typography variant="subtitle1">{article.title}</Typography>
											</Stack>
											<Typography color="text.secondary" variant="body2">
												{article.path}
											</Typography>
											<Typography color="text.secondary" variant="body2" sx={{
                mt: 1
              }}>
												{article.description}
											</Typography>
										</Box>)}
								</Stack>
							</Stack>)}
					</Stack>}
			</DialogContent>
		</Dialog>;
};