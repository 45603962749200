import * as React from 'react';

interface PopoverController<TElement, TData> {
	anchorRef: React.MutableRefObject<TElement | null>;
	handleOpen: (data?: TData) => void;
	handleClose: () => void;
	handleToggle: () => void;
	open: boolean;
	data?: TData;
}

export function usePopover<TElement = HTMLElement, TData = unknown>(): PopoverController<TElement, TData> {
	const anchorRef = React.useRef<TElement>(null);
	const [state, setState] = React.useState<{ open: boolean; data?: TData }>({
		open: false,
		data: undefined,
	});

	const handleOpen = React.useCallback((data?: TData) => {
		setState({ open: true, data });
	}, []);

	const handleClose = React.useCallback(() => {
		setState({ open: false });
	}, []);

	const handleToggle = React.useCallback(() => {
		setState((prev) => ({ ...prev, open: !prev.open }));
	}, []);

	return { ...state, anchorRef, handleClose, handleOpen, handleToggle };
}
